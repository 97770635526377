body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.videos {
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
}
.videos li {
  flex-basis: 50%;
  flex-shrink: 0;
  flex-grow: 1;
  padding: 10px;
  box-sizing: border-box;
}
h2 {
  font-size: 14px
}
.video-wrapper {
  aspect-ratio: 16 / 9;
  max-height: 40vh;
  margin: 0 auto;
}